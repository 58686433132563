import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import Grid from "@material-ui/core/Grid";

import style from "../../components/Dashboards/style";
import {checkAuth} from "../../helpers/auth";
import Results from '../../components/DashboardResults';
import axios from "axios";
import { Button } from "@material-ui/core";
import ReportsFiltering from "../../components/ReportsFiltering";
import ScrollTopLeft from "../../components/Miscellaneous/scrollTopLeft";
import moment from 'moment'

class SalesAfterLead extends React.Component {
    results = React.createRef();
    state = {
        config: null,
        selectedDimensions: {
            startDate: moment().add('days',  -30).format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD')  
        },
        additionalFilters: {
            
        }
    }
    componentDidMount() {
        checkAuth('media-planner');
        axios.get(`${process.env.SERVER_ENDPOINT}/reportsConfig/submissionsByGclid`).then(response => {
            this.setState({
                ...this.state,
                config: response.data
            })
        })
    }

    getColumns() {
        let cols = Object.assign([], this.state.config.columns);
        return cols;
    }

    onSelect(dimensions, additionalFilters) {
        let filters = {};
        for(let i in additionalFilters) {
            if(additionalFilters[i]) 
                filters[i] = additionalFilters[i];
        }
        this.setState({
            ...this.state,
            selectedDimensions: dimensions,
            additionalFilters: filters
        }, () => {
            this.results.current.loadData();
        })
    }

    getFilterParams() {
        let str = '';

        if(Object.keys(this.state.additionalFilters).length > 0) {
            for(let i in this.state.additionalFilters) {
                str += `&${encodeURIComponent(i)}=${this.state.additionalFilters[i]}`;
            }
        }
        return str;
    }

    getDimensionParams() {
        let str = '';
        for(let i in this.state.selectedDimensions) {
            if(typeof(this.state.selectedDimensions[i]) !== 'undefined') {
                if(typeof(this.state.selectedDimensions[i]) === 'string') {
                    str += `&${encodeURIComponent(i)}=${this.state.selectedDimensions[i]}`;
                } else {
                    str += `&${encodeURIComponent(i)}=${this.state.selectedDimensions[i].join(`&${i}=`)}`;
                }
            }
        }
        return str;
    }

    setHiddenCols(hiddenCols) {
        let config = {...this.state.config};
        config.defaultHiddenColumns = hiddenCols;
        this.setState({config: config});
    }

    render() {
        let cols = [];
        if(this.state.config !== null)
            cols = this.getColumns();

        
        return (
            <Layout>
                <SEO title="Welcome"/>
                <Grid container style={style.mainContainer}>
                    <Grid container justify="center">
                    {this.state.config !== null ?
                        <div style={style.tabContainer}>

                            <Results
                                ref={this.results}
                                columns={cols}
                                url={process.env.SERVER_ENDPOINT + this.state.config.url + `?${this.getFilterParams() }${this.getDimensionParams() }`} 
                                title='Sales After Lead'
                                defaultHiddenColumns={this.state.config.defaultHiddenColumns}
                                sort={{
                                    column: cols[1],
                                    asc: false
                                }}
                                pagination={true}
                                columnsFormat={this.state.config.columnsFormat}
                                exportCSVConfig={{
                                    name:'sales-after-lead',
                                    url: process.env.SERVER_ENDPOINT + this.state.config.url + `?${this.getFilterParams() }${this.getDimensionParams() }`
                                }}
                                dimensions={this.state.config.dimensions}
                                onHiddenColsChange={hiddenCols => this.setHiddenCols(hiddenCols) }

                            >
                                <h3>{this.state.config.title}</h3>
                                <ReportsFiltering
                                    columns={cols}
                                    defaultHiddenColumns={this.state.config.defaultHiddenColumns}
                                    onHiddenColsChange={hiddenCols => this.setHiddenCols(hiddenCols) }
                                    onSelect={this.onSelect.bind(this)}
                                    selected={{
                                        startDate: this.state.selectedDimensions.startDate,
                                        endDate: this.state.selectedDimensions.endDate
                                    }}
                                    type="sales-after-lead"
                                    config={this.state.config}
                                />
                            </Results>
                            <ScrollTopLeft />
                        </div>
                    : null}
                    </Grid>
                </Grid>
            </Layout>
        );
    }
}

export default SalesAfterLead;
